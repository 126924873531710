/*@import 'bootstrap/dist/css/bootstrap.css';*/

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 0.5rem;
  height: 100vh;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.row {
  margin-bottom: 2px;
}

.bi {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

/*
   * Sidebar
   */

@media (min-width: 768px) {
  .sidebar .offcanvas-lg {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
  }

  .navbar-search {
    display: block;
  }
}

.sidebar .nav-link {
  font-size: .875rem;
  font-weight: 500;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar-heading {
  font-size: .75rem;
}

/*
   * Navbar
   */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
}

/* Redactable Content Handler */
.secret { font-family: var(--redact); }
@font-face {
  font-family: Klingon;
  src: url(/klingon.ttf);
}
:root {
  --redect: "Sans-Serif"
}
#loginModal {
  overflow-y:auto;
}
.clickable {
  text-decoration: underline;
}
.cardmenu:hover {
  background-color:#2470dc;
}